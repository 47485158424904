<template>
  <div class="mt-5 wrapper p-5">
    <BaseTitle title="credit report" />
    <div v-if="isloading">Loading data please wait...</div>

    <b-table striped hover :items="items" borderless responsive="sm" small>
      <template #cell(reportId)="data">
        <span>
          <router-link
            :to="{
              name: 'supplementOrders',
              params: { reportId: data.item.reportId }
            }"
          >
            {{ data.item.reportId }}
          </router-link>
        </span>
      </template>

      <template #cell(borrower)="data">
        <span class="text-capitalize">{{ data.item.borrower }}</span>
      </template>

      <template #cell(coBorrower)="data">
        <span class="text-capitalize">
          {{ data.item.coBorrower ? data.item.coBorrower : '-' }}
        </span>
      </template>

      <template #cell(product)="data">
        <span class="text-capitalize">{{ data.item.product }}</span>
      </template>

      <template #cell(bureau)="data">
        <span class="text-uppercase">{{ data.item.bureau }}</span>
      </template>

      <template #cell(status)="data">
        <span
          :class="{ 'text-success': data.item.status === 'completed' }"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { apiService } from '../../scripts/services'
import BaseTitle from '../Base/BaseTitle.vue'
export default {
  components: { BaseTitle },
  data() {
    return {
      items: [],
      isloading: false
    }
  },
  mounted() {
    this.isloading = true
    apiService.getReports().then((r) => {
      this.isloading = false
      this.items = r.map((x) => {
        return {
          reportId: x.reportId,
          loanNumber: x.loanNumber,
          borrower: x.borrower,
          coBorrower: x.coBorrower,
          product: x.product,
          bureau: x.bureau,
          dateOrdered: x.dateOrdered,
          status: x.status
        }
      })
    })
  },
  methods: {}
}
</script>
