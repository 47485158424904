<template>
  <h4 class="text-capitalize mb-4 title-color">{{ title }}</h4>
</template>

<script>
export default {
  name: 'BaseTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.title-color {
  color: #003366;
}
</style>
